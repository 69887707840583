<template>
  <!-- eslint-disable -->
  <div class="bg-white center-content">
    <p style="font-size: 30px; font-weight: bold; text-align: center;">
      Unlimited Access to All Properties
    </p>
    <!-- <p class="info-text">
      With a honely pro subscription you’ll be equipped with the most powerful
      real estate data on the market. Access and download comprehensive property
      reports, home forecasts, neighborhood & moving trends, and more with a
      simple search.
    </p> -->
    <div class="subscription-plan-container">
      <!-- <div class="property-details-container">
        <div class="image-container">
          <img :src="image" alt="" />
        </div>
        <div class="address-container">
          <i class="fa fa-map-marker" style="color: grey; margin-right: 5px;" />
          <p style="margin-botton: 0px" class="no-mb">{{ displayAddress }}</p>
        </div>
      </div> -->
      <div class="subscription-feature-row">
        <p>Current Estimate</p>
        <!-- <p>Free</p> -->
        <i class="fa fa-check" style="color: #24cb43" />
      </div>
      <div class="subscription-feature-row">
        <p>3 Month Forecast</p>
        <!-- <p>Free</p> -->
        <i class="fa fa-check" style="color: #24cb43" />
      </div>
      <div class="subscription-feature-row">
        <p>1 Year Forecast</p>
        <!-- <p>Free</p> -->
        <i class="fa fa-check" style="color: #24cb43" />
      </div>
      <div class="subscription-feature-row">
        <p>2 Year Forecast</p>
        <i class="fa fa-check" style="color: #24cb43" />
      </div>
      <div class="subscription-feature-row">
        <p>3 Year Forecast</p>
        <i class="fa fa-check" style="color: #24cb43" />
      </div>
      <div class="subscription-feature-row">
        <p>Neighborhood Trends</p>
        <i class="fa fa-check" style="color: #24cb43" />
      </div>
      <div class="subscription-feature-row">
        <p>Buyer’s Score</p>
        <i class="fa fa-check" style="color: #24cb43" />
      </div>
      <div class="subscription-feature-row">
        <p>Property Investment Calculator</p>
        <i class="fa fa-check" style="color: #24cb43" />
      </div>
      <!-- <div class="subscription-feature-row">
        <p>Monthly Email Report</p>
        <p style="color: #24cb43">Subscribe</p>
      </div> -->
      <div class="pricing-container" v-if="hasFreeTrial">
        <p style="font-size: 42px; font-weight: bold;">$0.00</p>
        <p style="font-size: 12px;">free for 30 days</p>
      </div>
      <div class="pricing-container" v-else>
        <p style="font-size: 42px; font-weight: bold;">$2.99</p>
        <p style="font-size: 12px;">30-day access</p>
      </div>

      <div class="bottom-subscription-container">
        <!-- <div class="left-container">
          <input type="checkbox" v-model="subscription" />
        </div> -->
        <div class="right-container">
          <p style="margin-bottom: 10px; color: black">
            Monthly Subscription
          </p>

          <p style="font-size: 12px; padding: 0.5rem;" v-if="hasFreeTrial">
            Only $2.99/month after trial. Cancel anytime.
          </p>
          <p style="font-size: 12px; padding: 0.5rem;" v-else>
            You will be billed every 30 days for $2.99 until you cancel the
            subscription
          </p>
        </div>
      </div>
    </div>
    <!-- <p class="info-text">
      With a honely pro subscription you’ll be equipped with the most powerful
      real estate data on the market. Access and download comprehensive property
      reports, home forecasts, neighborhood & moving trends, and more with a
      simple search.
    </p> -->
    <button class="main-button" @click="redirectToCheckout()">Continue</button>
    <div class="continue-browsing-button" @click="redirectToForecastPage()">
      I want to continue browsing
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
export default {
  name: "Subscription Details",
  props: {
    searchQuery: String,
  },
  components: {},
  data() {
    return {
      displayAddress: decodeURI(this.$route.query.address),
      subscription: false,
      image: null,
      validImage: null,
      hasFreeTrial: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isCognitoUserLoggedIn", "cognitoUser"]),
  },
  mounted() {
    this.getForecastData();
    this.getUserDetail();
  },
  methods: {
    getUserDetail() {
      axios
        .get("https://api.honely.com/lookup-test/user_profile", {
          params: {
            email: this.$store.getters["auth/cognitoUser"].attributes.email,
          },
        })
        .then((response) => {
          this.hasFreeTrial = response.data.free_trial;
        })
        .catch((error) => {
          console.log("[ERROR] Failed to fetch user data");
          console.log(error.response.data.errorMessage);
        });
    },
    redirectToCheckout() {
      this.$router.push({
        path: "checkout",
        query: { address: this.$route.query.address, sub: this.subscription },
      });
      // For Redirecting back after checkout
      // this.$router.push({ path: "forecast", query: { address: this.$route.query.address } });
    },
    redirectToForecastPage() {
      this.$router.push({
        path: "forecast",
        query: { address: this.$route.query.address },
      });
    },
    getForecastData() {
      let body = {
        address: this.$route.query.address,
      };
      axios
        .get("https://api.honely.com/searches/dev/v1/forecast", {
          headers: {
            Authorization:
              "Bearer " + this.cognitoUser.signInUserSession.idToken.jwtToken,
          },
          params: body,
        })
        .then((res) => {
          this.property_id = res.data.property_forecast.property_id;

          console.log(res.data);
          this.checkImage(res.data);
        });
    },
    checkImage(forecast) {
      console.log("geting image", forecast);
      const self = this;
      if (forecast && forecast.property_forecast) {
        if (forecast.property_forecast.listing_key) {
          // Get ListHub image
          self.image =
            "https://listhub-property-images.s3.amazonaws.com/" +
            forecast.property_forecast.listing_key +
            "_1.jpg";
        } else if (
          forecast.property_forecast.latitude &&
          forecast.property_forecast.longitude
        ) {
          const url =
            "https://maps.googleapis.com/maps/api/streetview?size=600x400&location=" +
            forecast.property_forecast.latitude +
            "," +
            forecast.property_forecast.longitude +
            "&radius=100&return_error_code=true&source=outdoor&key=" +
            this.$mapsKey;
          fetch(url, {
            method: "GET",
            headers: {},
          })
            .then(function(response) {
              if (!response.ok) {
                throw Error(response.status);
              }
              return response.blob;
            })
            .then(function(blob) {
              self.image = url;
              self.validImage = true;
            })
            .catch((err) => {
              self.validImage = true;
              self.image = "/site_images/listing_default_image.png";
              console.log("[ERROR] Google image API failed =>", err);
            });
        }
      }
    },
  },
};
</script>
<style>
.center-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 20px;
}
.info-text {
  width: 80%;
  max-width: 1440px;
  text-align: center;
}
.subscription-plan-container {
  width: 100%;
  max-width: 450px;
  background-color: rgba(217, 217, 217, 0.1);
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subscription-plan-container p {
  margin: 0;
}
.property-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}
.address-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* height: 40px; */
}
.address-container .no-mb {
  margin-bottom: 0px !important;
}
.image-container {
  height: 100px;
  background-color: #d9d9d9;
  border-radius: 20px;
  width: 125px;
  margin-bottom: 20px;
  overflow: hidden;
  align-self: center;
}
.image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.subscription-feature-row {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  height: 40px;
  width: 80%;
}
.pricing-container {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.bottom-subscription-container {
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(217, 217, 217, 0.5);
  padding: 20px;
}
.bottom-subscription-container .right-container {
  width: 100%;
  text-align: center;
  min-width: 450px;
}
.bottom-subscription-container .left-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 25px;
}
.bottom-subscription-container .left-container input {
  height: 20px;
  width: 20px;
}
.main-button {
  background-color: #24cb43;
  color: white;
  border: none;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
}
.continue-browsing-button {
  font-size: 12px;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .info-text {
    width: 100%;
  }
  .subscription-plan-container {
    max-width: 350px;
  }
  .bottom-subscription-container .left-container {
    margin-left: 10px;
  }
}
</style>
