var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white center-content"},[_c('p',{staticStyle:{"font-size":"30px","font-weight":"bold","text-align":"center"}},[_vm._v(" Unlimited Access to All Properties ")]),_c('div',{staticClass:"subscription-plan-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),(_vm.hasFreeTrial)?_c('div',{staticClass:"pricing-container"},[_c('p',{staticStyle:{"font-size":"42px","font-weight":"bold"}},[_vm._v("$0.00")]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v("free for 30 days")])]):_c('div',{staticClass:"pricing-container"},[_c('p',{staticStyle:{"font-size":"42px","font-weight":"bold"}},[_vm._v("$2.99")]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v("30-day access")])]),_c('div',{staticClass:"bottom-subscription-container"},[_c('div',{staticClass:"right-container"},[_c('p',{staticStyle:{"margin-bottom":"10px","color":"black"}},[_vm._v(" Monthly Subscription ")]),(_vm.hasFreeTrial)?_c('p',{staticStyle:{"font-size":"12px","padding":"0.5rem"}},[_vm._v(" Only $2.99/month after trial. Cancel anytime. ")]):_c('p',{staticStyle:{"font-size":"12px","padding":"0.5rem"}},[_vm._v(" You will be billed every 30 days for $2.99 until you cancel the subscription ")])])])]),_c('button',{staticClass:"main-button",on:{"click":function($event){return _vm.redirectToCheckout()}}},[_vm._v("Continue")]),_c('div',{staticClass:"continue-browsing-button",on:{"click":function($event){return _vm.redirectToForecastPage()}}},[_vm._v(" I want to continue browsing ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-feature-row"},[_c('p',[_vm._v("Current Estimate")]),_c('i',{staticClass:"fa fa-check",staticStyle:{"color":"#24cb43"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-feature-row"},[_c('p',[_vm._v("3 Month Forecast")]),_c('i',{staticClass:"fa fa-check",staticStyle:{"color":"#24cb43"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-feature-row"},[_c('p',[_vm._v("1 Year Forecast")]),_c('i',{staticClass:"fa fa-check",staticStyle:{"color":"#24cb43"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-feature-row"},[_c('p',[_vm._v("2 Year Forecast")]),_c('i',{staticClass:"fa fa-check",staticStyle:{"color":"#24cb43"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-feature-row"},[_c('p',[_vm._v("3 Year Forecast")]),_c('i',{staticClass:"fa fa-check",staticStyle:{"color":"#24cb43"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-feature-row"},[_c('p',[_vm._v("Neighborhood Trends")]),_c('i',{staticClass:"fa fa-check",staticStyle:{"color":"#24cb43"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-feature-row"},[_c('p',[_vm._v("Buyer’s Score")]),_c('i',{staticClass:"fa fa-check",staticStyle:{"color":"#24cb43"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-feature-row"},[_c('p',[_vm._v("Property Investment Calculator")]),_c('i',{staticClass:"fa fa-check",staticStyle:{"color":"#24cb43"}})])
}]

export { render, staticRenderFns }